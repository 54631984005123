.contact {
  text-align: center;
  padding: 20px 0 30px;
}
h4 {
  margin-top: 10px;
  font-size: 1.3rem;
  font-weight: 600;
}
@keyframes amount {
  0% {
    width: 0;

    font-size: 0;
  }
  50% {
    font-size: 1;
  }
  100% {
    width: 100%;
    color: $text-white;
    font-size: 1.4rem;
  }
}

.members-amout {
  width: 70%;
  height: 35px;
  border: 2px solid $main-light;
  position: relative;
  overflow: hidden;
  margin: 15px auto;
  color: $text-white;
  font-size: 1.4rem;
  font-weight: 500;
  .amout-line {
    animation: amount 3.2s ease forwards;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 35px;
    background-color: $main-light;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
p,
a {
  font-size: 1.2rem;
}
a {
  color: $main-light;
  font-weight: 500;
}
.contact-links {
  margin-top: 35px;
  list-style: none;
  li {
    margin: 10px;
    font-size: 1.2rem;
    font-weight: 500;
    .links {
      color: $main-light;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  h4 {
    font-size: 1.4rem;
  }
  .members-amout {
    width: 55%;
    font-size: 1.5rem;
  }
  p {
    font-size: 1.3rem;
  }
  .contact-links {
    li {
      font-size: 1.3rem;
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  .members-amout {
    width: 30%;
    font-size: 1.6rem;
  }
  p {
    font-size: 1.3rem;
  }
  .contact-links {
    li {
      font-size: 1.3rem;
    }
  }
}
