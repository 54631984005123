.error-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 5%;
  position: relative;
  &::after {
    background: linear-gradient(#008bba, #18235b);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
  div {
    text-align: center;
    z-index: 1;
    h2 {
      color: $text-white;
    }
    img {
      width: 30%;
      margin: 10px 0;
    }
    p {
      margin: 20px 0;
      color: $text-light-gray;
    }

    .btn {
      margin: 20px auto;
      width: fit-content;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  .error-page {
    div {
      text-align: center;
      z-index: 1;
      margin-bottom: 50px;
      img {
        width: 25%;
      }
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .error-page {
    div {
      img {
        width: 15%;
      }
    }
  }
}
