.loading-page {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #182338d8;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    height: 100%;
    width: 100%;
    background: linear-gradient(#182338dd, #182338d8);
  }
  .loading-main {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 36%;
    z-index: 10;
    gap: 15px;
    img {
      width: 80%;
      margin: 20px 10%;
    }
    .loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      gap: 5px;
      .box {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: #fff;
        &:first-of-type {
          animation: loading-animation 2s 0.2s ease infinite;
        }
        &:nth-of-type(2) {
          animation: loading-animation 2s 0.5s ease infinite;
        }
        &:nth-of-type(3) {
          animation: loading-animation 2s 0.7s ease infinite;
        }
        &:nth-of-type(4) {
          animation: loading-animation 2s 0.9s ease infinite;
        }
        &:nth-of-type(5) {
          animation: loading-animation 2s 2.6s ease infinite;
        }
      }
    }
  }
}

/* *****LOADING PAGE***** */
@keyframes loading-animation {
  0% {
    opacity: 1;
    background-color: #008bba;
  }
  50% {
    opacity: 0.7;
    background-color: #008bba6b;
  }
  100% {
    opacity: 1;
    background-color: #008bba;
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  .loading-page {
    .loading-main {
      bottom: 30%;
      img {
        width: 60%;
        margin: 20px 20%;
      }
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .loading-page {
    bottom: 50%;
    .loading-main {
      img {
        width: 35%;
        margin: 20px 32.5%;
      }
    }
  }
}
