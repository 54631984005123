.raspored-treninga {
  text-align: center;
  padding: 20px 0 30px;
  border-collapse: collapse;
  list-style: none;
  h4 {
    font-size: 0.7rem;
    color: #444;
    margin-bottom: 25px;
  }
  .trening {
    width: 85%;
    margin: 0 auto 0;

    padding: 5px 10px 10px;
    .trening-grid {
      border: 2px solid #008bba;
      margin-bottom: 2px;
      display: grid;
      grid-template-columns: 2fr 5fr;
      border-collapse: collapse;
      h2 {
        margin: 0;
        border-right: 2px solid #008bba;
        background-color: $main-light-transparent;
        color: #182338;
        height: 100%;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 12px;
      }
      .trening-grid-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .trening-box {
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          padding: 6px 12px;
          &:first-of-type {
            border-right: 2px solid #008bba;
          }

          &:nth-of-type(3) {
            border-top: 2px solid #008bba;
            border-right: 2px solid #008bba;
          }
          &:nth-of-type(4) {
            border-top: 2px solid #008bba;
          }

          h4 {
            margin: 0;
            font-size: 0.8rem;
            font-weight: 600;
          }
          p {
            font-size: 0.8rem;
          }
        }
      }
    }
    .trening-info {
      width: 85%;
      margin: 0 auto;
      padding-left: 10px;
      gap: 15px;

      h5 {
        width: 100%;
        font-size: 1rem;
        margin-bottom: 5px;
        color: #444;
        font-weight: 500;
      }
      a {
        font-size: 1.1rem;
      }
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 700px) {
  .raspored-treninga {
    .trening {
      width: 75%;

      .trening-grid {
        h2 {
          font-size: 1.2rem;
        }
        .trening-box {
          h4 {
            font-size: 1rem;
          }
          p {
            font-size: 1.3rem;
          }
        }
      }
      .trening-info {
        h5 {
          font-size: 1.1rem;
        }
        a {
          font-size: 1.2rem;
        }
      }
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .raspored-treninga {
    .trening {
      width: 45%;

      .trening-grid {
        h2 {
          font-size: 1.2rem;
        }
        .trening-box {
          h4 {
            font-size: 1rem;
          }
          p {
            font-size: 1.3rem;
          }
        }
      }
      .trening-info {
        h5 {
          font-size: 1.1rem;
        }
        a {
          font-size: 1.2rem;
        }
      }
    }
  }
}
