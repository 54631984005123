#quiz-section {
  width: 100%;

  .go-back-btn {
    text-align: left;
    font-size: 1rem;
  }
  div {
    text-align: center;
    h2 {
      font-size: 1.2rem;
      font-weight: 600;
      color: $main-light;
      margin: 40px 0 20px;
    }
    p {
      margin: 15px 0;
      text-align: center;
    }
    .links {
      text-align: center;
      font-size: 1.6rem;
    }
  }
}

/* *****QUIZ***** */
.quiz {
  width: 95%;
  margin: 20px auto;
  padding: 20px 20px 5px;
  h1 {
    font-size: 1.6rem;
  }
  p {
    margin: 10px 0;
    font-size: 1.2rem;
  }
  ul {
    list-style: none;
    li {
      button {
        background-color: #008bba6b;
        width: 100%;
        padding: 6px;
        font-size: 1.3rem;
        margin: 10px 0;
        border: none;
      }
    }
  }
  .correct {
    background-color: #51d951;
  }
  .wrong {
    background-color: #e33d3d;
  }
  .clicked-correct {
    border: 3px solid #108910;
    background-color: #51d951;
    color: #222 !important;
    font-weight: 500;
  }
  .clicked-wrong {
    border: 3px solid #9a0a0a;
    background-color: #e33d3d;
    color: #222;
    font-weight: 500;
  }
}

.quiz-finish {
  width: 100%;
  text-align: center;
  h2 {
    font-size: 1.4rem;
    color: #008bba;
    margin: 15px auto;
  }
  p {
    font-size: 1rem;
  }
  button {
    margin: 20px;
    border: none;
    background: #008bba;
    color: #fff;
    padding: 6px 12px;
    font-size: 1.2rem;
  }
  .emoji {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    span {
      color: #def24b;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  /* *****QUIZ***** */
  .quiz {
    width: 70%;
    margin: 15px auto;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  /* *****QUIZ***** */
  .quiz {
    width: 55%;
    margin: 10px auto;
  }
}
