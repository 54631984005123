.matches-slider {
  margin: -30px auto 50px;
  h2 {
    font-size: 1.4rem;
  }
  .match-info-container {
    position: relative;
    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      background: none;
      padding-top: 7px;
      border-radius: 50%;
      transition: 0.5s;
      border: none;
      height: 50px;
      width: 50px;
      .fi {
        color: $main-light;
        font-size: 1.6rem;
      }
    }
    .left {
      left: 0%;
    }
    .right {
      right: 0%;
    }

    .match-result {
      margin: auto auto;
      width: 95%;
      padding: 20px;
      h3 {
        width: 80%;
        font-size: 1.1rem;
        margin: auto auto;
      }
      h2 {
        font-size: 1.6rem;
      }
    }
  }
  .slider-indicators {
    margin-top: -10px;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 5px;
    span {
      display: inline-block;
      background-color: #ccc;
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
    .active-match {
      background-color: $main-light;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  .matches-slider {
    .match-info-container {
      .left {
        left: 0%;
      }
      .right {
        right: 0%;
      }
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .matches-slider {
    width: 70%;
  }
}
