/* *****HOME***** */

.main-home {
  width: 100%;
  height: 50vh;
  position: relative;
  img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    object-position: center;
  }
  &::after {
    content: "";
    /* background-color: #00000080; */
    background: linear-gradient(#18235b, #008bba6b);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 50vh;
    width: 100%;
  }
  h1 {
    position: absolute;
    padding: 10px 20px;
    left: 50%;
    bottom: 0%;
    transform: translateX(-50%);
    width: 100%;
    font-weight: 900;
    color: #fff;
    z-index: 2;
    font-size: 2.2rem;
    text-align: center;
  }
}

.btn {
  font-size: 1.1rem;
  width: fit-content;
  display: inline-block;
  padding: 6px 15px;
  background-color: #008bba;
  border: none;
  color: #fff;
}

/* *****IMAGE POPUP*****  */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  background-color: #222222f2;
  align-items: center;
  z-index: 1000;
}

#popup {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  position: relative;
  .popup-btn {
    border: none;
    background: none;
    position: absolute;
    top: 46%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
  }
  .prev {
    left: 10px;
  }
  .next {
    right: 10px;
  }
  #close {
    position: absolute;
    top: -5px;
    color: #fff;
    font-size: 3rem;
    right: 10px;
    cursor: pointer;
  }
  span {
    position: relative;
    top: -2px;
    color: #ddd;
    background: none;
    font-size: 0.8rem;
  }
  #popupImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
/* *****FILTER BUTTON***** */
.filter-controls {
  label {
    margin-right: 10px;
  }
  .filter-btn {
    bottom: 5vh;
    left: 10px;
    border: 2px solid #008bba;
    z-index: 3;
    border-radius: 5px 5px 0 0;
    width: fit-content;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: 500;
    background-color: #fff;
    option {
      font-size: 0.9rem;
    }
  }
}

/* *****BACK TO TOP BUTTON***** */
.back-to-top-btn {
  position: fixed;
  bottom: 15vh;
  right: 10px;
  opacity: 0;
  z-index: 10;
  text-align: center;
  background: none;
  transition: opacity 0.3s ease-in-out;
  display: none;
  border: none;
  border-radius: 50%;
  background-color: #008bba9d;
  height: 3rem;
  width: 3rem;
  padding-top: 7px;

  i {
    font-size: 2rem;
    color: #fff;
  }
}

.back-to-top-btn.visible {
  display: block;
  animation: btn-opacity 0.4s ease-in-out forwards;
}

.back-to-top-btn.closing {
  animation: btn-opacity-reverse 0.4s ease-in-out forwards;
}

@keyframes btn-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes btn-opacity-reverse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 767px) {
  /* *****HOME***** */
  .main-home {
    h1 {
      bottom: -5%;
      font-size: 2.6rem;
      /*    filter: drop-shadow(2px 2px 10px #182338); */
    }
  }

  /* *****IMAGE POPUP*****  */
  #popup {
    width: 60%;
    max-height: 80vh;
    object-fit: cover;
  }
  /* *****BACK TO TOP BUTTON***** */
  .back-to-top-btn {
    bottom: 15vh;
    right: 2%;
    width: 2rem;
    height: 2rem;
    padding-top: 3px;
    i {
      font-size: 1.6rem;
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  /* *****HOME***** */
  .main-home {
    h1 {
      bottom: -3%;
      width: 100%;
      margin: 0 auto;
      font-size: 2.8rem;
    }
  }
  /* *****IMAGE POPUP*****  */
  .overlay {
    z-index: 100;
  }

  #popup {
    height: 70%;
    border: 2px solid #222;
    #popupImage {
      width: 100%;
      object-fit: cover;
    }
  }

  /* *****BACK TO TOP BUTTON***** */
  .back-to-top-btn {
    bottom: 15vh;
    right: 15%;
  }
}
