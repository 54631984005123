#navigation {
  position: fixed;
  background-color: #18235b;
  top: 0;
  right: 0;
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  z-index: 100;
  transition: all 0.5s;
  .navigation-logo {
    width: 5vh;
  }
  .night-mode-btn {
    position: absolute;
    right: 15px;
    top: 9vh;
    z-index: 1000;
    font-size: 1.4rem;
    background: none;
    border: none;
    img {
      width: 1.4rem;
    }
  }
}

.submenu {
  list-style: none;
  margin: 10px 0;
  li {
    margin-bottom: 10px !important;
    .submenu-links {
      font-size: 1.2rem;
      font-weight: 400;
    }
  }
}

.nav-links li:hover .submenu {
  display: block;
}

.nav-links {
  list-style: none;
  li {
    text-align: center;
    margin-bottom: 15px;
    a,
    span {
      text-align: left;
      color: #fff;
      text-decoration: none;
      font-size: 1.4rem;
      font-weight: 600;
      list-style: none;
      transition: all 0.5s;
      &:hover {
        padding-left: 5px;
      }
    }

    span {
      margin-left: 10px;
    }
  }
  .navbar-logo {
    width: 15vh;
    margin-bottom: 20px;
  }
}

.hamburger {
  display: none;
  cursor: pointer;
  z-index: 1001;
  .bar {
    display: block;
    border-radius: 5px;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    /*  */
    &:nth-child(1) {
      width: 35px;
      height: 2.5px;
    }

    &:nth-child(2) {
      margin-right: 0;
      width: 30px;
      height: 2.5px;
    }
    &:nth-child(3) {
      margin-right: 0;
      width: 25px;
      height: 2.5px;
    }
  }
}

.hamburger {
  display: block;
  z-index: 1001;
}
.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}
.hamburger.active .bar:nth-child(1) {
  width: 25px;
  transform: translate(-2px, 2px) translateX(5px) rotate(45deg);
}
.hamburger.active .bar:nth-child(3) {
  width: 25px;
  transform: translateY(-15.5px) rotate(-45deg);
}

.nav-links {
  position: fixed;
  right: -1000%;
  top: 0;
  min-height: 100vh;
  flex-direction: column;
  background: linear-gradient(#204c7d, #154075f4);
  width: 100%;
  box-shadow: 2px 5px 10px #1f1f1fb6;
  text-align: center;
  transition: 0.3s;
  padding: 40px 15px 10px;
  z-index: 1000;
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  a {
    font-size: 1.8rem;
    color: #fff;
    text-decoration: none;
    i {
      transition: all 0.5s;
      &:hover {
        color: #008bba;
      }
    }
  }
}

.nav-links.active {
  right: 0%;
}

/* TABLET */
@media only screen and (min-width: 700px) {
  #navigation {
    .nav-links {
      padding: 20px 15px 30px;
      li {
        margin-bottom: 9px;
        a {
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
    }
    .submenu {
      li {
        .submenu-links {
          color: #ccccccd4;
          font-size: 1.15rem;
        }
      }
    }
    .navbar-links {
      font-weight: 500;
      font-size: 1.2rem;
    }
    .social-links {
      margin-top: 10px;
    }
  }
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  #navigation {
    padding: 20px 10%;
    .nav-links {
      width: 45%;
    }
    .night-mode-btn {
      right: 10%;
    }
  }
}
