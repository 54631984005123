.seniori {
  text-align: center;
}

.team-grid {
  padding: 5px 20px;
  text-align: left;
  .player-box {
    width: 65%;
    position: relative;
    margin: 20px auto;
    img {
      width: 100%;
    }
    h3 {
      position: absolute;
      bottom: 0;
      left: -5px;
      background-color: #30466f;
      /*    background-color: #008bbadc; */
      font-size: 1rem;
      font-weight: 900;
      padding: 15px;
      border-top-right-radius: 35px;
      color: #fff;
    }
    p {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #5884bf;
      /*   background-color: #333333af; */
      font-size: 2rem;
      font-weight: 900;
      padding: 15px;
      border-bottom-left-radius: 35px;
      color: #fff;
    }
  }
}
.overlay-player {
  padding: 0 20px;

  #popup {
    .player-info {
      position: absolute;
      bottom: -5px;
      left: -10px;
      width: 60%;
      background-color: #30466f;
      font-size: 1.1rem;
      padding: 10px;
      border-top-right-radius: 35px;
      h5 {
        font-size: 0.8rem;
        color: #fff;
      }
      p {
        color: #eee;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 650px) {
  .team-grid {
    padding: 10px 10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .player-box {
      width: 85%;
      h3 {
        font-size: 1.1rem;
      }
      p {
        font-size: 2rem;
      }
    }
  }

  .overlay-player {
    padding: 0 20%;
    .player-info {
      width: 45%;
      font-size: 1.2rem;
      h5 {
        font-size: 1rem;
      }
      p {
        font-size: 1.2rem;
      }
    }
  }
}
/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .team-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    .player-box {
      width: 95%;
      h3 {
        font-size: 0.8rem;
      }
      p {
        font-size: 1.4rem;
      }
    }
  }
  .overlay-player {
    #popup {
      width: 400px;
      height: 400px;
      img {
        object-fit: cover;
      }
      .player-info {
        bottom: -5px;
        left: -5px;
        width: 50%;
        font-size: 1.2rem;
        h5 {
          font-size: 0.8rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }
}
