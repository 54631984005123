.add-player-form {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.added-players-list {
  margin-top: 20px;
  text-decoration: none;
  list-style: none;
  text-align: center;
  li {
    margin: 5px auto;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1rem;
        margin-left: 5px;
      }
    }
  }
}
/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 700px) {
  .add-player-form {
    width: 80%;
    margin: 0 10%;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .add-player-form {
    width: 80%;
    margin: 0 20%;
  }
}
