.login-box {
  width: 100%;
  padding: 20px;
  text-align: center;
  input {
    width: 60%;
    margin: 5px 20% 25px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid $main-light;
  }
  button {
    width: 60%;
    margin: 20px 20%;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: $text-white;
    font-weight: 600;
    font-size: 1rem;
    background-color: $main-light;
  }
}
