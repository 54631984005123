.home-grid {
  padding: 20px;
  grid-template-columns: repeat(1fr, 1);
  .home-btn {
    width: 100%;
    margin: 10px 0;
    position: relative;
    z-index: 1;
    border-radius: 25px;
    height: 5rem;
    overflow: hidden;

    a {
      background-color: #182338a7;
      font-size: 1.4rem;
      font-weight: 600;
      z-index: 2000;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: $text-white;
    }
  }
}
/* AddStandings */
.table-six {
  .row {
    grid-template-columns: 0.8fr 5.5fr 1fr 1.5fr 1.5fr 1fr;
    input {
      width: 100%;
      padding: 4px 8px;
      margin: 0 0 10px;
      border-radius: 5px;
      background-color: $sponsors-bg;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 700px) {
  .home-grid {
    .home-btn {
      width: 70%;
      margin: 10px 15%;
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .home-grid {
    .home-btn {
      width: 60%;
      margin: 10px 20%;
    }
  }
}
