.sponsors {
  margin-bottom: 0;
  width: 100%;
  padding: 20px;
  background: $sponsors-bg;
  .slider-box {
    a {
      display: inline-block;
      height: 200px;
      img {
        width: 40%;
        margin: 30px 30% 0px;
      }
    }
  }
}
.sponsors-desktop {
  display: none;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.sponsors-grid {
  background: $sponsors-bg;
  padding: 20px 15%;
  display: grid;
  text-align: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  img {
    width: 80%;
    margin: 0 auto;
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 700px) {
  /* *****SPONSORS******/
  .sponsors {
    display: none;
  }
  .sponsors-desktop {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    background-color: $sponsors-bg;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 20px 5%;
    gap: 10px;

    img {
      width: 70%;
      margin: 5px auto;
    }
  }
  .sponsors-grid {
    grid-template-columns: repeat(8, 1fr);
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  /* *****SPONSORS******/
  .sponsors {
    display: none;
  }
  .sponsors-desktop {
    margin-top: 20px;
    background-color: $sponsors-bg;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 20px 30%;
    gap: 10px;

    img {
      width: 85%;
      margin: 5px auto;
    }
  }
  .sponsors-grid {
    background: $sponsors-bg;
    padding: 20px 20%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 15px;
    img {
      width: 50%;
      margin: 0 auto;
    }
  }
}
