/* *****DRESOVI***** */
.kit-slider {
  position: relative;
  .kit-button {
    width: 15%;
    margin: 5px auto;
    .slider-img {
      width: 25%;
      margin: 0 37.5%;
    }
  }
  .btn-slide {
    background: none;
    padding-top: 7px;
    border-radius: 50%;
    transition: 0.5s;
    border: none;
    height: 50px;
    width: 50px;
    .fi {
      color: $main-light;
      font-size: 1.6rem;
    }
  }

  #left-slide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5%;
    z-index: 9;
  }
  #right-slide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
    z-index: 9;
  }

  .kit-box {
    overflow: hidden;
    text-align: center;
    margin: 10px 20px;
    border-radius: 15px;
    transition: ease-in 0.5s;
    h3 {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }
    .kit-img {
      width: 100%;
    }
    .sponsors-img {
      animation: opacity 8s linear;
      width: 40%;
      margin: 0 auto 25px;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  /* *****DRESOVI***** */
  .kit-slider {
    width: 55%;
    margin: 15px auto;
    position: relative;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  /* *****DRESOVI***** */
  .kit-slider {
    width: 50%;
    .kit-button {
      width: 15%;
      margin: 5px auto;
      .slider-img {
        width: 25%;
        margin: 0 37.5%;
      }
    }
  }
}
