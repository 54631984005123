* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  text-decoration: none;
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
}

.shutdown-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #182338;
  color: $text-white;

  img {
    width: 5rem;
    margin: 20px auto;
  }
}

.light-border {
  width: 45%;
  height: 1px;
  background-color: #008bba88;
  margin: 25px auto;
}

@keyframes slide-right {
  0% {
    opacity: 0;
    margin-left: -30px;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}
@keyframes slide-left {
  0% {
    opacity: 0;
    margin-left: 30px;
  }
  100% {
    opacity: 1;
    margin-right: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* *****SECTIONS***** */
.section {
  width: 100%;
  padding: 15px 25px 25px;
  .go-back-btn {
    background-color: #008bba;
    color: #fff;
    padding: 6px 12px;
    margin: 20px 0 40px !important;
    font-size: 1rem;
    border: none;
  }
  label {
    color: $main-light;
    font-weight: 500;
  }
  h2 {
    text-align: center;
    font-size: 1.6rem;
  }
  h3 {
    font-size: 1.4rem;
    color: $main-light;
  }
  h4 {
    font-size: 1.4rem;
    color: $text-dark;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .league-title {
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
    color: #008bba;
  }
  .form {
    padding: 20px;
    input,
    select,
    textarea {
      resize: none;
      width: 100%;
      padding: 10px 15px;
      margin: 0 0 10px;
      border-radius: 5px;
      background-color: $sponsors-bg;
    }
    .publish {
      background-color: #5deb5d;
    }
  }
}
.remove-margin {
  margin-top: -35px;
}
.add-margin {
  margin-bottom: 30px;
}
.remove-btn {
  padding-bottom: 8px;
  border: none;
  color: #ee3b3b;
  background: none;
  font-weight: 600;
  margin: 0 5px;
  border: none;
}
.add-btn {
  padding: 6px 12px;
  border-radius: 5px;
  background-color: #367cc7;
  border: none;
}

.info-box {
  width: fit-content;
  margin: 10px auto;
  text-align: center;
  padding: 20px 30px;
  background-color: $sponsors-bg;
  p {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
  span {
    color: #008bba;
    font-weight: 600;
  }
}
.success-message {
  color: #18e318;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.warning-message {
  color: red;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 0.8rem;
}
.message {
  text-align: center;
  color: #5deb5d;
  margin: 20px 0;
}
.center {
  text-align: center;
}
/* *****NIGHT MODE***** */
.nightmode {
  .section {
    background-color: #182338;
    h2,
    h4 {
      color: $text-white;
    }
    h3 {
      color: #eee;
    }
    h5,
    p {
      color: #ddd;
    }
    .league-title {
      color: #008bba;
    }
    span {
      color: $main-light;
    }
    .calendar-img {
      span {
        color: #bbb;
      }
    }
    .season-matches-top {
      background-color: #182338;
      color: $text-white;
      h3 {
        color: $main-light;
      }
    }

    .table {
      h6 {
        color: $text-light-gray;
      }
    }
    .news-preview {
      p {
        color: $text-white;
      }
    }
    .novosti-box {
      h4 {
        color: $text-white;
      }
    }
    .novosti-open {
      h5 {
        color: $main-light;
      }
      .img-author {
        color: #bbb !important;
      }
    }
    .subtitle {
      color: #008bba;
    }
    .light-border {
      background-color: #ffffff88;
    }
  }
  ul {
    color: $text-white;
  }
  .oprema {
    .oprema-box {
      .kit-box {
        background-color: #ffffff06;
        h4 {
          color: $text-white;
        }
        p {
          color: $main-light;
        }
      }
    }
    .modal {
      .modal-content {
        background-color: $sponsors-bg;
        p {
          color: $text-white;
        }
        .oprema-btn,
        .close {
          color: $text-white;
        }
      }
    }
  }

  .pagination {
    button {
      color: #eee;
    }
    .active {
      color: #008bba;
    }
  }
  .sponsors,
  .sponsors-grid,
  .sponsors-desktop {
    background: #182338f4;
    margin-top: 0;
  }
  .sponsors-desktop {
    margin-top: 0;
  }
  footer {
    margin-top: 0;
    background: linear-gradient(#182338, #182338f4);
  }
  .nav-links {
    background: linear-gradient(#182338, #182338f4);
  }
  .main-home {
    img {
      filter: grayscale(60%);
    }
    &::after {
      background: linear-gradient(#182338, #18233853, #182338e7);
    }
  }
  .quiz {
    button {
      color: $text-white;
    }
  }
  .error-page {
    &::after {
      background: linear-gradient(#182338, #182338f4);
    }
  }

  .team-name {
    color: #eee !important;
  }

  .raspored-treninga {
    .trening {
      .trening-info {
        h5 {
          color: #ddd;
        }
      }
    }
  }
  .matches-slider {
    .slider-indicators {
      span {
        background-color: #fff;
      }
      .active-match {
        background-color: $main-light;
      }
    }
  }
  .match-details {
    ul {
      .details {
        span {
          color: #bbb !important;
        }
      }
      .lineups {
        color: $text-white;
        .lineups-stadium {
          .players {
            span {
              color: $text-white;
            }
          }
        }
      }
    }
  }
  .podsused {
    background-color: #3ac6f45b;
    color: $text-white;
  }
  .news-not-found {
    background-color: #182338;
    color: #fff;
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 700px) {
  /* *****SECTIONS***** */
  .section {
    width: 100%;
    padding: 20px 15%;
    margin: 0 auto;
    h2 {
      font-size: 1.7rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
  .remove-margin {
    margin-top: -35px !important;
  }
  .form {
    width: 80%;
    margin: 0 10%;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  /* *****SECTIONS***** */
  .section {
    width: 100%;
    padding: 20px 22.5%;
    h2 {
      font-size: 1.6rem;
    }
    h3 {
      font-size: 1.5rem;
    }
    .form {
      width: 60%;
      margin: 0 20%;
    }
  }
  .light-border {
    width: 20%;
    margin: 30px auto;
  }
}
