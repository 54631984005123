.addMatches-form {
  width: 100%;
  padding: 20px 5px;
  font-size: 1.1rem;
  text-align: center;
  h3 {
    margin: 20px 0 10px;
    font-size: 1.2rem;
  }
  input,
  select {
    width: 100%;
    padding: 10px;
    margin: 0 0 10px;
    border-radius: 5px;
    background-color: $sponsors-bg;
  }
  .club-flex {
    width: 100%;
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    .result {
      margin: 5px auto;
      width: 60px;
      text-align: center;
    }
  }
  .match-events {
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 2fr 2fr 2fr 1fr;
  }
  .remove-btn {
    padding-bottom: 8px;
    border: none;
    color: #ee3b3b;
    background: none;
    font-weight: 600;
    margin: 0 5px;
  }
  .add-btn {
    padding: 6px 12px;
    border-radius: 5px;
    background-color: #367cc7;
  }
  .publish {
    display: inline-block;
    width: 100%;

    margin: 20px 0;
    background-color: #5deb5d;
  }
  .add-player {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr;
  }
}

.lineups {
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }
  .player {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    span {
      color: $text-white;
      font-size: 0.52rem;
    }
    .player-img {
      width: 32px;
    }
  }
  .GK {
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
  .LB {
    left: 6%;
    bottom: 37%;
  }
  .LCB {
    left: 27%;
    bottom: 30%;
  }
  .CB {
    left: 50%;
    transform: translateX(-50%);
    bottom: 36%;
  }
  .RCB {
    right: 30%;
    bottom: 30%;
  }
  .RB {
    right: 6%;
    bottom: 37%;
  }
  .LCDM {
    left: 30%;
    top: 30%;
  }
  .RCDM {
    right: 30%;
    top: 30%;
  }
  .RCM {
    bottom: 50%;
    right: 20%;
  }
  .LCM {
    bottom: 50%;
    left: 20%;
  }
  .CM {
    left: 50%;
    transform: translateX(-50%);
    top: 22%;
  }
  .LM {
    left: 10%;
    top: 24%;
  }
  .RM {
    right: 10%;
    top: 24%;
  }
  .RW {
    right: 15%;
    top: 8%;
  }
  .LW {
    left: 15%;
    top: 8%;
  }
  .ST {
    left: 50%;
    transform: translateX(-50%);
    top: 0%;
  }
  .LST {
    left: 30%;
    top: 0%;
  }
  .RST {
    right: 30%;
    top: 0%;
  }
  .CF {
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
  }
}
.bench-players {
  left: 0;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  .player {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    span {
      font-size: 0.5em;
      font-weight: 500;
    }
    .player-img {
      width: 26px;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 700px) {
  .addMatches-form {
    width: 80%;
    margin: 0 10%;
  }
}
/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .addMatches-form {
    width: 60%;
    margin: 0 20%;
  }
}
