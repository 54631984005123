.add-seniors {
  .team-grid {
    .player-item {
      width: 100%;
      margin: 10px auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      h3 {
        font-size: 1.1rem;
        color: $main-light;
      }
      h5 {
        font-size: 1rem;
      }
      .player-info {
        text-align: left;
        display: flex;
        gap: 10px;
      }
      p {
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 5px;
        span {
          font-size: 0.7rem;
        }
      }
    }
  }
}
