.calendar-box {
  display: grid;
  grid-template-columns: 1fr auto 6fr;
  margin-bottom: 10px;
  h4 {
    font-size: 1.2rem;
    margin-top: 5px;
    line-height: 0.9rem;
  }
  h4,
  span {
    padding-right: 5px;
    text-align: center;
    font-weight: 700;
  }
}
.calendar-img {
  width: 90%;
  margin: 0 auto 15px;
  img {
    width: 100%;
    margin: 10px auto 0px;
  }
  p,
  span {
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 10px;
  }
  span {
    display: inline-block;
    width: 100%;
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 700px) {
  .calendar-box {
    margin-bottom: 20px;
    h4 {
      margin-top: 5px;
      line-height: 1.4rem;
      text-align: right;
    }
    h4,
    span {
      font-size: 1.3rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
  .calendar-img {
    width: 70%;
    margin: 0 auto 15px;
    img {
      width: 100%;
      margin: 10px auto 0px;
    }
    p {
      font-size: 0.8rem;
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .calendar-box {
    grid-template-columns: auto auto 6fr;
    h4,
    span {
      font-size: 1.3rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
  .calendar-img {
    width: 60%;
    margin: 0 auto 15px;
    p {
      font-size: 0.9rem;
    }
  }
}
