/* *****STANDINGS***** */

.standings-btn {
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
  color: $main-light;
  font-weight: 600;
  background: none;
  border: none;
}
.table {
  text-align: left;
  margin: 20px 0;
  display: grid;
  grid-template-columns: (1, 1fr);
  .row {
    width: 100%;
    padding: 5px 5px;
    display: grid;
    grid-template-columns: 0.8fr 5.5fr 1fr 1.5fr 1fr;
    h5 {
      font-size: 1rem;
    }
    h6 {
      font-size: 1rem;
      color: $text-gray;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: middle;
      &:first-of-type {
        margin-right: 10px;
      }
      img {
        width: 1rem;
        margin-right: 5px;
      }
    }
  }
}
.podsused {
  background-color: #3ac6f45b;
  color: $text-dark;
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  /* *****STANDINGS***** */
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  /* *****STANDINGS***** */

  .standings-btn {
    font-size: 1.1rem;
  }
  .table {
    width: 65%;
    margin: 10px auto;
    .row {
      h5 {
        font-size: 1.1rem;
      }
      h6 {
        font-size: 1.1rem;
        color: $text-gray;
      }
      p {
        font-size: 1.1rem;
        font-weight: 500;
        img {
          width: 1.1rem;
        }
      }
    }
  }
}
