.gallery {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}
.more-btn {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  margin: 30px auto;
  border: none;
}
/* *****ADD GALLERY LIST***** */
.gallery-list {
  width: 100%;
  text-align: center;
  .gallery-item {
    padding: 5px 20px;
    display: grid;
    grid-template-columns: 1.2fr 4fr auto auto;
    text-align: left;
    img {
      width: 100%;
      height: 90%;
      object-position: center;
      object-fit: cover;
    }
    p {
      span {
        font-size: 0.8rem;
      }
      padding: 0px 10px;
      font-size: 0.9rem;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    img {
      width: 100%;
      margin: 0px auto;
    }
  }
  .gallery-list {
    width: 80%;
    margin: 10px auto;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    img {
      width: 100%;
      margin: 0px auto;
      object-position: center bottom;
    }
  }
  /* *****ADD GALLERY LIST***** */
  .gallery-list {
    text-align: center;
    width: 60%;
    margin: 10px auto;
  }
}
