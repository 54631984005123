$main-light: #008bba;
$main-dark: #18235b;
$main-light-transparent: #008bba6b;
$main-dark-transparent: #18235bc3;
$light-gray-transparent: #eeeeee80;
$sponsors-bg: #87a8ce29;
$shadow-box: #1f1f1fb6;

$text-dark: #222;
$text-gray: #333;
$text-white: #fff;
$text-light-gray: #eee;
$text-dark-light-gray: #ccc;
