.news-preview {
  padding: 20px;
  img {
    width: 100%;
  }
  span {
    width: 100%;
    display: inline-block;
    color: $main-light;
    font-size: 1rem;
    margin-bottom: 15px;
  }
  .category {
    font-size: 1rem;
    color: gray;
    margin-bottom: 15px;
  }
  h3 {
    font-size: 1.6rem;
    margin: 20px 0;
  }
  p {
    font-size: 1.1rem;
    color: $text-dark;
    margin: 0px 0 10px;
  }
  h4 {
    color: $main-light;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
  }
  h5 {
    color: $main-light;
    font-size: 1.1rem;
    font-weight: 500;
  }
}
