.oprema {
  width: 100%;
  padding: 20px;
  text-align: center;
  h3 {
    margin: 20px 0;
  }
  h5 {
    margin: 30px;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.6rem;
    span {
      background-color: $main-light;
      padding: 4px 10px;
      margin: 5px 2px 5px 0;
      color: $text-white !important;
    }
  }

  .kit-button {
    width: fit-content;
    margin-top: 20px;
    img {
      width: 40%;
      margin: 0 30%;
    }
  }
  .oprema-box {
    width: 90%;
    margin: 10px auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    .kit-box {
      position: relative;
      background-color: #fff4f4c4;
      width: 100%;
      padding: 12px;
      img {
        transition: all 0.5s;
        width: 100%;
        &:hover {
          transform: scale(1.05);
        }
      }
      h4 {
        font-size: 1.2rem;
        color: #222;
      }
      p {
        color: #444;
        font-size: 0.9rem;
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: #1f1f1fdb;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%);
      background-color: #dce2e874;
      padding: 20px;
      img {
        width: 100%;
      }
      p {
        font-size: 1.2rem;
        color: $text-dark;
        font-weight: 600;
      }

      .oprema-btn {
        transform: translateY(-50%);
        font-size: 3rem;
        font-weight: 600;
        color: #333;
        border: none;
        background: none;
        position: absolute;
        top: 46%;
      }
      .prev-btn {
        left: 5%;
      }
      .next-btn {
        right: 5%;
      }

      .close {
        position: absolute;
        color: $text-dark;
        top: 10px;
        right: 20px;
        font-size: 2rem;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 767px) {
  .oprema {
    .kit-button {
      img {
        width: 20%;
        margin: 0 40%;
      }
    }
    .oprema-box {
      width: 70%;
    }

    .modal {
      .modal-content {
        width: 60%;
      }
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .oprema {
    width: 100%;
    padding: 10px 20px 20px;
    text-align: center;
    h3 {
      margin: 30px 0;
    }
    .kit-button {
      img {
        width: 10%;
        margin: 20px 45% 0;
      }
    }
    .oprema-box {
      width: 100%;
      padding: 20px 25%;
      margin: 0 auto;
      margin: 10px auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      .kit-box {
        background-color: #f0eaeaaf;
        width: 100%;
        padding: 20px;
        img {
          transition: all 0.5s;
          width: 100%;
          &:hover {
            transform: scale(1.05);
          }
        }
        h4 {
          font-size: 1.2rem;
          color: #222;
        }
        p {
          color: #444;
          font-size: 0.9rem;
        }
      }
    }

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 100;
      background-color: #1f1f1fdb;
      display: flex;
      justify-content: center;
      align-items: center;
      .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30%;
        transform: translate(-50%, -50%);
        background-color: #fcfdfebc;
        padding: 20px;
        img {
          width: 100%;
        }
      }
    }
  }
}
