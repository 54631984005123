.privacy-terms {
  text-align: left;
  p {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  ul {
    margin: 20px 0;
    list-style: none;
    li {
      margin: 10px 0 20px;
      h3 {
        font-size: 1.2rem;
        margin-bottom: 5px;
      }
      p {
        margin-left: 15px;
        font-size: 1.1rem;
      }
      .links {
        color: #008bba;
      }
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 767px) {
  .privacy-terms {
    p {
      font-size: 1.2rem;
    }
    ul {
      li {
        h3 {
          font-size: 1.3rem;
        }
        p {
          margin-left: 15px;
          font-size: 1.2rem;
        }
      }
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
}
