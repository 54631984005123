.season-matches-top {
  top: -10vh;
  position: relative;
  padding: 20px 0px 0;
  z-index: 2;
  text-align: center;
  background-color: #fff;
  border-radius: 15px 15px 0px 0px;
  h3 {
    width: 100%;
    margin: 15px 0 25px;
    font-size: 1.4rem;
    color: #222;
  }
  .season-matches {
    margin-top: 20px;
  }
}

.match-info {
  padding: 20px 10px 20px;
  animation: fade 1.5s ease-in-out forwards;
  list-style: none;
  h2 {
    font-size: 1.1rem;
    font-weight: 800;
    z-index: 4;
  }
  text-align: center;
  .team-name {
    margin: 5px 0;
  }
  h5 {
    animation: fade 1.5s ease-in-out forwards;

    margin: 5px 0;
    font-weight: 600;
    font-size: 0.8rem;
    i {
      color: #008bba;
      margin-right: 5px;
    }
  }
  .match-result {
    padding: 10px;
    width: 100%;
    gap: 2px;
    display: grid;
    grid-template-columns: 2fr auto 2fr;
    text-align: center;
    .team-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
      h3 {
        font-size: 0.9rem;
        font-weight: 700;
      }
      img {
        width: 40%;
        margin: 0 auto;
      }
    }

    .final-result {
      animation: fade 1s ease-in-out forwards;
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        font-size: 2.2rem;
        font-weight: 800;
      }
    }
  }
}

.season-border {
  height: 1px;
  width: 30%;
  margin: 20px auto;
  background-color: #008bbacc;
  z-index: 15;
}
.season-search {
  position: fixed;
  bottom: 5vh;
  left: 10px;
  border: 2px solid #008bba;
  z-index: 3;
  border-radius: 5px 5px 0 0;
  width: fit-content;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 500;
  background-color: #fff;
  option {
    font-size: 0.9rem;
  }
}

/* TABLET */

@media only screen and (min-width: 700px) {
  h3 {
    font-size: 1.6rem;
    color: #008bba;
  }
  .season-matches {
    margin-top: 20px;
  }
}
.season-border {
  width: 15%;
}
.match-info {
  h2 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 0.9rem;
  }
  .match-result {
    .team-info {
      h3 {
        font-size: 1rem;
        font-weight: 700;
      }
      img {
        width: 30%;
      }
    }

    .final-result {
      h2 {
        font-size: 2.8rem;
        font-weight: 800;
      }
    }
  }
  .season-search {
    width: 15% !important;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .season-border {
    width: 10%;
    margin: 30px auto;
  }
  .match-info {
    width: 80%;
    margin: 5px 10%;
  }
  .season-search {
    margin-left: 15%;
  }
}
