.statistics {
  text-align: center;
  margin: 20px 0;
  display: grid;
  grid-template-columns: (1, 1fr);
  .row {
    width: 80%;
    margin: 0 auto;
    padding: 5px 10px;
    display: grid;
    text-align: left;
    grid-template-columns: 0.4fr 5fr auto;
    h5 {
      font-size: 1rem;
    }
    h6 {
      font-size: 1rem;
      color: $text-gray;
    }
    p {
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 1rem;
        margin: 0 6px;
      }
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  /* *****STANDINGS***** */
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .statistics {
    width: 70%;
    margin: 10px auto;
    .row {
      h5 {
        font-size: 1.1rem;
      }
      h6 {
        font-size: 1.1rem;
        color: $text-gray;
      }
      p {
        font-size: 1.1rem;
        font-weight: 500;
        img {
          width: 1.1rem;
        }
      }
    }
  }
}
