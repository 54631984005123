.show-btn-group {
  margin: 0px 0px 20px;
  .show-btn {
    padding: 12px 20px;
    margin: 0 5px;
    border-radius: 5px;
    background-color: $main-light-transparent;

    border: none;
    color: rgba(207, 205, 205, 0.878);
    font-weight: 500;
    transition: all 0.5s;
    &::after {
      opacity: 0.8;
    }
  }
  .selected {
    background-color: $main-light;
    color: $text-white;
  }
}

.selection-matches {
  margin: 10px 10px 15px;
  list-style: none;
  h3 {
    font-size: 1.1rem;
  }
  .info-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .info-2 {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      font-size: 0.9rem;
    }
    h2 {
      font-size: 1rem;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  .selection-matches {
    width: 80%;
    margin: 10px auto;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .selection-matches {
    width: 60%;
    margin: 10px auto;
  }
}
