.news-btn {
  width: 100%;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    width: fit-content;
  }
}
.novosti-box {
  width: 100%;
  list-style: none;
  margin: 20px 0;
  height: 240px;
  cursor: pointer;

  .novosti-box-link {
    height: 100%;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(#18235b15, #18235b38, #182338, #182338);
      z-index: 2;
    }
    .novosti-img {
      position: relative;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 80%;
        object-fit: cover;
        object-position: bottom center;
      }
    }
    .novosti-info {
      position: absolute;
      z-index: 3;
      height: 100%;
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      bottom: 0;
      h3 {
        display: -webkit-box;
        display: box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        font-size: 1.1rem;
        margin: 0;
        color: #fff !important;
      }
      span {
        font-size: 0.8rem;
        color: #008bba;
      }
    }
  }
}

.in-progress {
  background-color: #008bba;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 2rem;
    color: #fff;
  }
  a {
    margin-top: 15px;
    background-color: #fff;
    color: #222;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 600;
    transition: all 0.5s ease;
    &:hover {
      background-color: #18235b;
      color: #fff;
    }
  }
}

/* *****NOVOSTI OPEN***** */

.novosti-open {
  padding: 20px 0;
  .img-author {
    position: relative;
    top: -15px;
    color: #777 !important;
    font-size: 0.9rem;
  }
  h5 {
    color: $main-light;
    font-size: 1rem;
    font-weight: 600;
  }
  .novosti-open-img {
    margin-top: 40px;
  }
  img {
    margin: 15px auto;
    width: 100%;
  }
  .subtitle {
    color: #008bba;
  }
  h2 {
    margin: 15px 0;
    font-size: 1.4rem;
    text-align: left;
  }
  .novosti-text {
    h5 {
      font-size: 1rem;
      color: $main-light;
      margin-top: 15px;
      font-weight: 700;
    }
    margin: 20px 0 35px;
    p {
      font-size: 1.1rem;
      margin: 5px 0;
    }
  }
  span {
    color: #008bba;
  }
}

/* *****PAGINATION***** */
.pagination {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    font-size: 1.2rem;
    border: none;
    background: none;
    padding: 10px;
    color: #008bba;
  }
  .active {
    color: #18235b;
    font-weight: 500;
  }
}

/* *****ZANIMLJVIOSTI PAGE***** */
.zanimljivosti {
  width: 95%;
  margin: 20px auto 5px;
  padding: 20px;
  h2 {
    margin-bottom: 20px;
  }
  p {
    margin: 10px 0;
  }
}

.news-not-found {
  width: 100%;
  padding: 8vh 10px 60px;
  text-align: center;
  img {
    width: 20%;
    margin: 20px 40%;
  }
  h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 30px;
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  .grid-2 {
    width: 100%;
    margin: 10px auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .novosti-box {
    width: 100%;

    margin: 15px auto;
    .novosti-box-link {
      .novosti-img {
        img {
          height: 90%;
        }
        .novosti-btn {
          font-size: 1.1rem;
        }
      }
      .novosti-info {
        h3 {
          font-size: 1.15rem;
        }
        span {
          font-size: 0.9rem;
          color: #008bba;
        }
      }
    }
  }

  /* *****NOVOSTI OPEN***** */
  .novosti-open {
    h2 {
      font-size: 1.5rem;
    }

    img {
      display: block;
      width: 90%;
      margin: 15px auto;
    }
    .img-author {
      padding-left: 5%;
    }
    .novosti-text {
      p {
        font-size: 1.2rem;
      }
    }
  }

  /* *****ZANIMLJVIOSTI PAGE***** */
  .zanimljivosti {
    width: 70%;
    margin: 15px auto;
  }
  .news-not-found {
    img {
      width: 10%;
      margin: 20px 45%;
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .novosti-box {
    width: 100%;
    padding: 5px;
    height: auto;
    .novosti-box-link {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          #18235b15,
          #18235b1c,
          #182338e2 60%,
          #182338,
          #182338
        );
        z-index: 2;
      }
      .novosti-img {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .novosti-btn {
          font-size: 1rem;
        }
        img {
          width: 100%;
          height: 90%;
          object-fit: cover;
          object-position: bottom;
        }
      }
      .novosti-info {
        h3 {
          font-size: 1.1rem;
        }
        span {
          font-size: 0.9rem;
          color: #008bba;
        }
      }
    }
  }
  /* *****ZANIMLJVIOSTI PAGE***** */
  .zanimljivosti {
    width: 55%;
    margin: 10px auto;
  }
}
