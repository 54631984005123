.utakmice-btn {
  position: relative;
  top: 2vh;
  position: relative;
  text-align: center;
}

.match-details {
  position: relative;
  width: 95%;
  margin: 15px auto 10px;

  ul {
    .details {
      margin: 5px auto;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      text-align: center;
      gap: 20px;
      color: #666;

      .right {
        margin-left: 50%;
        text-align: left;
      }
      .left {
        margin-right: 50%;
        text-align: right;
      }
      span {
        font-size: 0.75rem;
        font-weight: 500;
        img {
          width: 0.7rem;
          margin: 0 2px;
        }
      }
      .substitution {
        span {
          display: block;
          width: 100%;
          margin-bottom: 0;
        }
        .sub-out {
          font-size: 0.8rem;
          color: #8a8a8a;
          margin-top: -1px;
          .rotate {
            transform: rotate(180deg);
          }
        }
        .sub-in {
          font-size: 0.85rem;
          .rotate {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .lineups {
    margin-top: 15px;

    .lineups-stadium {
      position: relative;
      width: 100%;
      img {
        width: 100%;
      }
      .player {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        span {
          color: $text-white;
          font-size: 0.52rem;
        }
        .player-img {
          width: 32px;
        }
      }
      .GK {
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
      }
      .LB {
        left: 6%;
        bottom: 37%;
      }
      .LCB {
        left: 27%;
        bottom: 30%;
      }
      .CB {
        left: 50%;
        transform: translateX(-50%);
        bottom: 36%;
      }
      .RCB {
        right: 30%;
        bottom: 30%;
      }
      .RB {
        right: 6%;
        bottom: 37%;
      }
      .LCDM {
        left: 30%;
        top: 30%;
      }
      .RCDM {
        right: 30%;
        top: 30%;
      }
      .RCM {
        bottom: 50%;
        right: 20%;
      }
      .LCM {
        bottom: 50%;
        left: 20%;
      }
      .CM {
        left: 50%;
        transform: translateX(-50%);
        top: 22%;
      }
      .LM {
        left: 10%;
        top: 24%;
      }
      .RM {
        right: 10%;
        top: 24%;
      }
      .RW {
        right: 15%;
        top: 8%;
      }
      .LW {
        left: 15%;
        top: 8%;
      }
      .ST {
        left: 50%;
        transform: translateX(-50%);
        top: 0%;
      }
      .LST {
        left: 30%;
        top: 0%;
      }
      .RST {
        right: 30%;
        top: 0%;
      }
      .CF {
        left: 50%;
        transform: translateX(-50%);
        top: 10%;
      }
    }
    .bench-players {
      left: 0;
      gap: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      .player {
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;
        h6 {
          font-size: 0.5em;
          font-weight: 500;
        }
        .player-img {
          width: 26px;
        }
      }
    }
  }
}
/*  */
.details-btn {
  margin-top: 5px;
  font-size: 0.9rem;
  border: none;
  background: none;
  font-weight: 500;
  color: $main-light;
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  .match-details {
    ul {
      .details {
        span {
          font-size: 0.9rem;
          img {
            width: 0.75rem;
          }
        }
      }
    }
    .lineups {
      width: 90%;
      margin: 15px auto;
      .lineups-stadium {
        .player {
          span {
            font-size: 0.85rem;
          }
          .player-img {
            width: 45px;
          }
        }
      }
      .bench-players {
        .player {
          h6 {
            font-size: 0.8em;
            font-weight: 500;
          }
          .player-img {
            width: 32px;
          }
        }
      }
    }
  }
  .details-btn {
    font-size: 1rem;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .match-details {
    ul {
      .details {
        span {
          font-size: 0.9rem;
          img {
            width: 0.6rem;
          }
        }
      }
    }
    .lineups {
      width: 70%;
      margin: 0 auto;
      .lineups-stadium {
        .player {
          span {
            font-size: 0.78rem;
          }
          .player-img {
            width: 40px;
          }
        }
      }
      .bench-players {
        .player {
          h6 {
            font-size: 0.68em;
            font-weight: 500;
          }
          .player-img {
            width: 28px;
          }
        }
      }
    }
  }
  .details-btn {
    font-size: 1.05rem;
  }
}
