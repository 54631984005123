.member {
  width: 100%;
  padding: 20px;
  .member-box {
    text-align: center;

    img {
      width: 85%;
      margin: 10px auto 20px;
    }
    h5 {
      font-size: 1.1rem;
      font-weight: 600;
    }
    h6 {
      font-size: 0.9rem;
      text-align: left;
      margin: 10px;
      color: $main-light;
      font-weight: 600;
    }
    ul {
      width: 80%;
      margin: 10px auto;
      text-decoration: none;
      text-align: left;

      li {
        margin: 10px 0;
        font-size: 1rem;
        line-height: 1.6rem;
        span {
          background-color: $main-light;
          padding: 4px 10px;
          margin: 5px 0px;
          color: $text-white !important;
        }
      }
    }
  }
}
.member-price {
  margin: 25px 5% 15px;
  text-align: center;
  button {
    border: none;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 5px auto;
    padding: 10px 20px;
    width: fit-content;
    color: $text-white;
    background-color: $main-light;
    transition: 0.5s all;
    &:hover {
      opacity: 0.8;
    }
  }
}
/* MEMBER HOME */
.member-home {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  h5 {
    font-size: 1rem;
    font-weight: 600;
  }
  img {
    width: 100%;
    margin: 20px 0 30px;
  }
}

/* ČLANOVI PRIVATE */
.members-private {
  padding: 30px 20px 20px;
  h3 {
    text-align: center;
    margin: 15px 0 5px;
  }
  .members-news {
    width: 100%;

    img {
      width: 100%;
    }
    h4 {
      margin-top: 0;
      margin-bottom: 20px;
    }
    h5 {
      font-size: 1rem;
      color: $main-light;
    }

    p {
      font-size: 1rem;
      margin-bottom: 10px;
    }
    .date {
      margin-top: 10px;
      font-size: 0.9rem;
      color: $text-white;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }
}
.video {
  width: 100%;
  position: relative;

  iframe {
    width: 100%;
    min-height: 250px;

    object-position: center;
    object-fit: cover;
  }
  h4 {
    margin-top: 0px;
    font-size: 1.1rem;
  }
  p {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  .video-cover {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: 20%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 10;
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 600px) {
  .member {
    width: 70%;
    margin: 0 auto;
    .member-box:first-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  /* MEMBER HOME */
  .member-home {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    h5 {
      font-size: 1rem;
      font-weight: 600;
    }
    img {
      width: 80%;
      margin: 15px auto 20px;
    }
  }
  /* ČLANOVI PRIVATE */

  .video {
    width: 100%;

    iframe {
      width: 100%;
      min-height: 300px;
    }
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .member {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .member-box {
      h5 {
        margin-top: 25px;
      }
    }
  }
  /* MEMBER HOME */
  .member-home {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    h5 {
      font-size: 1rem;
      font-weight: 600;
    }
    img {
      width: 80%;
      margin: 15px 10% 20px;
    }
  }
  /* ČLANOVI PRIVATE */

  .video {
    width: 100%;

    iframe {
      width: 100%;
      min-height: 420px;
    }
  }
}
