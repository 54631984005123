/* *****ADD NEWS***** */

.news-form {
  padding: 5px 20px;
  .image-preview {
    width: 100%;
  }
  .alt-input {
    display: flex;
    gap: 10px;
    margin: 0 0 10px;
    input {
      font-size: 1rem;
      &::placeholder {
        font-size: 0.9rem;
      }
    }
  }
  label {
    width: 100%;
    font-size: 1.2rem;
    display: inline-block;
  }
  input,
  textarea,
  select {
    font-size: 1.2rem;
    width: 100%;
    margin: 5px 0;
    padding: 10px 20px;
    border-radius: 15px;
    resize: none;
    border: none;
    background-color: $sponsors-bg;
    color: #c9c6c6;
    border-bottom: 1px solid $main-light;
    &::placeholder,
    &:focus {
      color: gray;
      font-size: 1rem;
      background: none;
    }
  }
  select {
    color: gray;
    font-size: 1rem;
    &:focus {
      background-color: $sponsors-bg;
    }
    &:-moz-focusring {
      background-color: $sponsors-bg;
    }
  }
  option {
    color: #222;
  }
  textarea {
    margin-bottom: 0;
  }
  .news-img-form {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  .news-btn {
    padding: 6px 12px;
    background-color: $main-light;
    border-radius: 5px;
    color: $text-white;
    border: none;
    margin: 2px;
  }
  .show-btn {
    margin: 20px 0;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #5deb5d;
    border: none;
    border-radius: 5px;
    font-weight: 600;
  }
  .publish {
    background-color: #367cc7;
  }
  .back-btn {
    margin-bottom: 30px;
  }
  .remove-btn {
    background-color: #ee3b3b;
  }
}
.error,
.error-message {
  font-size: 0.9rem;
  color: #ee3b3b !important;
}

.news-list {
  width: 100%;
  margin: 30px 0 20px;
  text-align: center;
  h3 {
    margin-bottom: 15px;
  }
  .news-item {
    padding: 5px 20px;
    display: grid;
    grid-template-columns: 1.2fr 4fr auto auto;
    text-align: left;
    img {
      width: 100%;
      object-position: center;
      object-fit: cover;
    }
    span {
      margin-left: 10px;
      font-size: 0.7rem;
    }
    h3 {
      padding: 0px 10px;
      font-weight: 600;
      font-size: 0.75rem;
    }
  }
}

/* ---------------MEDIA QUERIES--------------- */

/* TABLET */

@media only screen and (min-width: 700px) {
  .news-form {
    padding: 5px 20px;
    width: 80%;
    margin: 0 auto;
  }
}
